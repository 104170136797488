@import url("https://fonts.googleapis.com/css?family=Noto+Sans+TC");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.react-toast-notifications__container {
  z-index: 3000 !important;
}

dl dt {
  float: left;
  clear: left;
  margin-right: 0.5em;
  width: 150px;
}
dl dd {
  float: left;
  margin-left: 1em;
}
