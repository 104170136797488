.orgchart.myChart {
    /*background-image: linear-gradient(90deg,
            rgba(33, 90, 136, 0.15) 10%,
            rgba(0, 0, 0, 0) 10%),
        linear-gradient(rgba(33, 90, 136, 0.15) 10%, rgba(0, 0, 0, 0) 10%) !important;*/
    background-image: none !important;
}

.orgchart.myChart>ul>li>ul li::before {
    border-top-color: #d3d3d3;
}

.orgchart.myChart>ul>li>ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
    background-color: #d3d3d3;
}

.orgchart.myChart .oc-node .position {
    box-sizing: border-box;
    background-color: #d3d3d3;
    color: #fff;
    width: 130px;
    height: 65px;
    padding: 2px;
}

.orgchart.myChart .oc-node .fullname {
    box-sizing: border-box;
    color: #d3d3d3;
    background-color: #fff;
    width: 130px;
    height: 65px;
    padding: 2px;
    border: 1px solid #d3d3d3;
}

.orgchart.myChart .oci-chevron-up::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    vertical-align: text-bottom;
    width: 0.625rem;
    height: 0.625rem;
    border-width: 0 0.2rem 0.2rem 0;
    border-style: solid;
    border-color: #000;
    background: transparent;
    transform: rotate(-135deg);
}

.orgchart.myChart .oci-chevron-right::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    width: 0.625rem;
    height: 0.625rem;
    border-width: 0 0.2rem 0.2rem 0;
    border-style: solid;
    border-color: #000;
    background: transparent;
    transform: rotate(-45deg);
}

.orgchart.myChart .oci-chevron-down::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    width: 0.625rem;
    height: 0.625rem;
    border-width: 0 0.2rem 0.2rem 0;
    border-style: solid;
    border-color: #000;
    background: transparent;
    transform: rotate(45deg);
}

.orgchart.myChart .oci-chevron-left::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    width: 0.625rem;
    height: 0.625rem;
    border-width: 0 0.2rem 0.2rem 0;
    border-style: solid;
    border-color: #000;
    background: transparent;
    transform: rotate(135deg);
}

.orgchart.myChart ul li .oc-node.selected {
    background-color: rgba(0, 0, 0, 0.08);
}

.orgchart.myChart ul li .oc-node:hover {
    background-color: rgba(0, 0, 0, 0.08);
}